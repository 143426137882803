import { useRef } from 'react'
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify'
import './index.css'

const Contact = () => {
    const formRef = useRef(null)

    const handleSubmit = (e) => {
        e.preventDefault();
        sendEmail()
        formRef.current.reset()
    }

    const sendEmail = async () => {
        const response = await emailjs.sendForm('service_w14z45u', 'template_durpz2d', formRef.current, 'NSIDFm_9WnT3UTypx')
        if (response.status === 200) {
            toast("Your message sent successfully.", {
                type: "success",
                autoClose: 5000,
                position: "top-center"
            });
        } else {
            toast("Failed to send your message.", {
                type: "error",
                autoClose: 5000,
                position: "top-center"
            });
        }
    };

    return (
        <div className="form__container">
            <form ref={formRef} onSubmit={handleSubmit}>
                <legend>Contact Me</legend>
                <div className="form-group full-name">
                    <input type="text" name='firstname' required placeholder="First Name" />
                    <input type="text" name='lastname' required placeholder="Last Name" />
                </div>
                <div className="form-group">
                    <input type="email" name='email' required placeholder='Email' />
                </div>
                <div className="form-group">
                    <textarea type="text" rows="6" name='message' required placeholder='Message...'></textarea>
                </div>
                <div className="form-group">
                    <button>Send</button>
                </div>
            </form>
        </div>
    )
}

export default Contact