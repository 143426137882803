import './index.css'
import { FaChalkboardTeacher } from "react-icons/fa"
import { AiFillGithub } from 'react-icons/ai'

const Project = ({ image, title, description, live_demo, github }) => {
    return (
        <article className="project">
            <img src={image} alt={title} />
            <p className='header'>Title</p>
            <h2 className='title'>{title}</h2>
            <small className='description'>{description}</small>
            <div className="buttons">
                <a className='btn btn-dark' href={live_demo} target="_blank" rel="noreferrer"><FaChalkboardTeacher style={{ marginRight: "5px" }} /> Live Demo</a>
                <a className='btn btn-dark' href={github} target="_blank" rel="noreferrer"><AiFillGithub style={{ marginRight: "5px" }} /> GitHub</a>
            </div>
        </article>
    )
}

export default Project