import { Link, useLocation } from "react-router-dom"
import { AiFillHome, AiFillProject, AiFillContacts } from 'react-icons/ai'
import { GiAchievement } from 'react-icons/gi'

import "./index.css"

const Navbar = () => {
    const location = useLocation()

    return (
        <nav>
            <Link to="/" className={location.pathname === "/" ? "active" : ""}><AiFillHome /></Link>
            <Link to="/projects" className={location.pathname === "/projects" ? "active" : ""}><AiFillProject /></Link>
            <Link to="/about" className={["/about", "/experience", "/education"].includes(location.pathname) ? "active" : ""}><GiAchievement /></Link>
            <Link to="/contact" className={location.pathname === "/contact" ? "active" : ""}><AiFillContacts /></Link>
        </nav>
    )
}

export default Navbar