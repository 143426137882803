import { Link, Outlet, useLocation } from "react-router-dom"
import { FaMedal, FaUserAlt } from 'react-icons/fa'
import { AiFillStar } from 'react-icons/ai'
import './index.css'

const About = () => {
    const location = useLocation()
    return (
        <section className="about__container">
            <div className="about">
                <h1>About Me</h1>
                <div className="links">
                    <Link to="/about" className={location.pathname === "/about" ? "active" : ""}><FaUserAlt /> <span>About Me</span></Link>
                    <Link to="/experience" className={location.pathname === "/experience" ? "active" : ""}><AiFillStar /> <span>Experience</span></Link>
                    <Link to="/education" className={location.pathname === "/education" ? "active" : ""}><FaMedal /> <span>Education</span></Link>
                </div>
                <Outlet />
            </div>
        </section>
    )
}

export default About