import { AiOutlineGithub, AiFillLinkedin } from 'react-icons/ai'
import Typed from "react-typed"
import './index.css'

const Home = () => {
  return (
    <section className='home'>
      <h1>Amrit Upreti</h1>
      <div> I'm <Typed
        strings={
          [
            "Fullstack Developer",
            "Python Intermediate"
          ]}
        typeSpeed={110}
        backSpeed={70}
        loop={true}
        style={{ fontWeight: "700", color: "#0563BB" }
        }
      /></div >
      <div className="social-media-links">
        <a href="https://github.com/amritupreti" target="_blank" rel="noreferrer"><AiOutlineGithub /></a>
        <a href="https://www.linkedin.com/in/amrit-upreti-7943a4176/" target="_blank" rel="noreferrer"><AiFillLinkedin /></a>
      </div>
    </section >
  )
}

export default Home