import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Layout from './pages/layout';
import Home from './pages/home'
import Projects from './pages/projects'
import About from './pages/about';
import Contact from './pages/contact'
import AboutMe from './pages/about/components/about_me';
import Experience from './pages/about/components/experience';
import Education from './pages/about/components/education';

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route path='/' element={<Home />} />
          <Route path='projects' element={<Projects />} />
          <Route element={<About />}>
            <Route path='about' element={<AboutMe />} />
            <Route path='experience' element={<Experience />} />
            <Route path='education' element={<Education />} />
          </Route>
          <Route path='contact' element={<Contact />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
