import Project from "./components/project"
import './index.css'

const projects = [
  {
    id: 1,
    title: "Project 1",
    image: "https://d2slcw3kip6qmk.cloudfront.net/marketing/blog/2016Q4/8-steps-to-build-a-project-management-timeline@2x.png",
    live_demo: "#",
    description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. and some random text",
    github: "https://github.com/amritupreti/"
  },
  {
    id: 2,
    title: "Project 2",
    image: "https://d2slcw3kip6qmk.cloudfront.net/marketing/blog/2016Q4/8-steps-to-build-a-project-management-timeline@2x.png",
    live_demo: "#",
    description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. and some random text",
    github: "https://github.com/amritupreti/"
  },
  {
    id: 3,
    title: "Project 3",
    image: "https://d2slcw3kip6qmk.cloudfront.net/marketing/blog/2016Q4/8-steps-to-build-a-project-management-timeline@2x.png",
    live_demo: "#",
    description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. and some random text",
    github: "https://github.com/amritupreti/"
  },
  {
    id: 4,
    title: "Project 2",
    image: "https://d2slcw3kip6qmk.cloudfront.net/marketing/blog/2016Q4/8-steps-to-build-a-project-management-timeline@2x.png",
    live_demo: "#",
    description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. and some random text",
    github: "https://github.com/amritupreti/"
  },
  {
    id: 5,
    title: "Project 3",
    image: "https://d2slcw3kip6qmk.cloudfront.net/marketing/blog/2016Q4/8-steps-to-build-a-project-management-timeline@2x.png",
    live_demo: "#",
    description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. and some random text",
    github: "https://github.com/amritupreti/"
  },
]

const Projects = () => {
  return (
    <div className="projects__container">
      <section>
        <h1>My Projects</h1>
        <section className="projects">
          {projects.map(project => <Project key={project.id} {...project} />)}
        </section>
      </section>
    </div>
  )
}

export default Projects