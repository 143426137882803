import { FiCheckCircle } from 'react-icons/fi'
import './index.css'

const AboutMe = () => {
    return (
        <section className="skills__container">
            <h2>About Me</h2>
            <div className="skill_types">
                <article className="skill_type">
                    <h3>Frontend Skills</h3>
                    <div className="skills">
                        <div className="skill">
                            <div className="skill-detail">
                                <p><FiCheckCircle className='skill_icon' /> <span>HTML</span></p>
                                <p className="skill_level"><small>Pro</small></p>
                            </div>
                        </div>
                        <div className="skill">
                            <div className="skill-detail">
                                <p><FiCheckCircle className='skill_icon' /> <span>CSS</span></p>
                                <p className="skill_level"><small>Pro</small></p>
                            </div>
                        </div>
                        <div className="skill">
                            <div className="skill-detail">
                                <p><FiCheckCircle className='skill_icon' /> <span>JS</span></p>
                                <p className="skill_level"><small>Mid-Level</small></p>
                            </div>
                        </div>
                        <div className="skill">
                            <div className="skill-detail">
                                <p><FiCheckCircle className='skill_icon' /> <span>Bootstrap</span></p>
                                <p className="skill_level"><small>Mid-Level</small></p>
                            </div>
                        </div>
                        <div className="skill">
                            <div className="skill-detail">
                                <p><FiCheckCircle className='skill_icon' /> <span>Material UI</span></p>
                                <p className="skill_level"><small>Mid-Level</small></p>
                            </div>
                        </div>
                        <div className="skill">
                            <div className="skill-detail">
                                <p><FiCheckCircle className='skill_icon' /> <span>React</span></p>
                                <p className="skill_level"><small>Mid-Level</small></p>
                            </div>
                        </div>
                    </div>
                </article>
                <article className="skill_type">
                    <h3>Backend Skills</h3>
                    <div className="skills">
                        <div className="skill">
                            <div className="skill-detail">
                                <p><FiCheckCircle className='skill_icon' /> <span>Python</span></p>
                                <p className="skill_level"><small>Mid-Level</small></p>
                            </div>
                        </div>
                        <div className="skill">
                            <div className="skill-detail">
                                <p><FiCheckCircle className='skill_icon' /> <span>Django</span></p>
                                <p className="skill_level"><small>Mid-Level</small></p>
                            </div>
                        </div>
                        <div className="skill">
                            <div className="skill-detail">
                                <p><FiCheckCircle className='skill_icon' /> <span>REST API</span></p>
                                <p className="skill_level"><small>Mid-Level</small></p>
                            </div>
                        </div>
                        <div className="skill">
                            <div className="skill-detail">
                                <p><FiCheckCircle className='skill_icon' /> <span>Node JS</span></p>
                                <p className="skill_level"><small>Beginner</small></p>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </section >
    )
}

export default AboutMe