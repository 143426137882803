import { Outlet } from "react-router-dom"
import Navbar from "../navbar"
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import "./index.css"

const Layout = () => {
    return (
        <>
            <Navbar />
            <main className="content">
                <div className="content__container">
                    <ToastContainer />
                    <Outlet />
                </div>
            </main>
        </>
    )
}

export default Layout